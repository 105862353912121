
// var noscroll = true;
var winTop = $(window).scrollTop();
var section = 1;
var animateTime = 800;

var isMobile = false;
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    isMobile = true;
}

if(isMobile) {
    $('html').removeClass('no-scroll').addClass("mobile");
    $('#js-main').removeClass('main--hide').addClass("main__mobile");
    $('#js-intro').addClass("intro__mobile");
}

if (winTop > 1) {
    section = 2;
    $('#index').removeClass('no-scroll');
    $('#js-intro').removeClass("intro--show");
    $('#js-intro').addClass("intro--hide");

    $('#js-main').removeClass("main--hide");
    $('#js-main').addClass("main--show");
}

$('html,body').mousewheel(function (event) {

    winTop = $(window).scrollTop();

    console.log(section, winTop);

    if (winTop < 50 && !isMobile) {
        if (event.deltaY > 0) {          // top
            introScroll('top', 0);
        }
        if (event.deltaY < 0) {         // bottom
            introScroll('bottom', 0);
        }
    }
});

function introScroll(direction, winTop) {
    var bodyScroll = $('#body').hasClass("no-scroll");
    if (winTop == 0 && !bodyScroll) {
        if (direction === 'bottom') {
            if (section == 1) {
                section = 2;
                animate('to');
            } else if (section == 2) {
                setTimeout(function () {
                    $('#index').removeClass('no-scroll');
                }, animateTime);
            }
        } else if (direction === 'top') {
            if (section == 2) {
                section = 1;
                animate('from');
                $('#index').addClass('no-scroll');
            }
        }
    }

}

function animate(FromTo) {
    if (FromTo == 'to') {
        $('#js-intro').removeClass("intro--show");
        $('#js-intro').addClass("intro--hide");

        $('#js-main').removeClass("main--hide");
        $('#js-main').addClass("main--show");
        setTimeout(function () {
            $('#js-main').css('z-index', 2);
        }, animateTime );
    }
    if (FromTo == 'from') {
        $('#js-intro').removeClass("intro--hide");
        $('#js-intro').addClass("intro--show");

        $('#js-main').removeClass("main--show");
        $('#js-main').addClass("main--hide").css('z-index', 0);
    }
}

$(document).scroll(function () {
    if (isMobile == false) {
        introScroll();
    }
});


// animate.css
/*$.fn.extend({
    animateCss: function(animationName, callback) {
        var animationEnd = (function(el) {
            var animations = {
                animation: 'animationend',
                OAnimation: 'oAnimationEnd',
                MozAnimation: 'mozAnimationEnd',
                WebkitAnimation: 'webkitAnimationEnd',
            };

            for (var t in animations) {
                if (el.style[t] !== undefined) {
                    return animations[t];
                }
            }
        })(document.createElement('div'));

        this.addClass('animated ' + animationName).one(animationEnd, function() {
            $(this).removeClass('animated ' + animationName);

            if (typeof callback === 'function') callback();
        });

        return this;
    },
});*/

$(document).ready(function () {

    var windowWidth = $( window ).width();
    var md = 992;
    var sm = 768;
    
    var page = $('html').attr('id');
    
    var headerMenuVisible = false;
    var appCenterVisible = false;
    
    $('.menu__item--dropdown').click(function () {
        $('.menu').toggleClass('menu--expanded');
        $('.menu__item--dropdown').toggleClass('menu__item--expanded');
        $('.menu__item--dropdown .submenu').toggleClass('submenu--visible');
    });
    $('.menu__item--dropdown .menu__title').click(function (e) {
        e.preventDefault();
    });
    
    $('.header__menu-btn').click(function () {
        if (!headerMenuVisible && !appCenterVisible) {
            showHeaderMenu();
            headerMenuVisible = true;
        } else if (headerMenuVisible && !appCenterVisible) {
            showHeaderMenu();
            headerMenuVisible = false;
        } else if (!headerMenuVisible && appCenterVisible) {
            showAppCenter();
            showHeaderMenu();
            headerMenuVisible = true;
            appCenterVisible = false;
        }
    });
    
    $('.menu-right__app_center, .intro__button, .main__button').click(function () {
        if (!appCenterVisible && !headerMenuVisible) {
            showAppCenter();
            appCenterVisible = true;
        } else if (appCenterVisible && !headerMenuVisible ) {
            showAppCenter();
            appCenterVisible = false;
        } else if (!appCenterVisible && headerMenuVisible ) {
            showHeaderMenu();
            showAppCenter();
            appCenterVisible = true;
            headerMenuVisible = false;
        }
    });
    
    function showHeaderMenu() {
        $('.header__menu-btn').toggleClass('header__menu-btn--close');
        $('.header__mobile').toggleClass('header__mobile--open');
    }
    
    function showAppCenter() {
        $('.menu-right__app_center').toggleClass('menu-right__app_center--active');
        $('.app-center').toggleClass('app-center--open');
        $('.header').toggleClass('header--open');
        $('#body').toggleClass('no-scroll');
    }
    
    
    // TODO: change toggle classes for header buttons (menu and app-center)
    
    if ( windowWidth < md && (page !== 'index' && page !== 'partnership') || windowWidth < sm ){
        $('.header').addClass('header__mobile');
    }

    

    $('.tabs__tab').click(function () {
       var tab_id = $(this).attr('data-tab');
    
       $('.tabs__tab').removeClass('current');
       $('.tabs__content').removeClass('current');
       $('.tabs__list').removeClass('animate');
    
       $(this).addClass('current');
       $('.'+tab_id).addClass('current');
       $('.'+tab_id + '.tabs__list' ).addClass('animate');
    
    });

    $('.slider__container').draggable({
        axis: "x",
        containment: ".slider"
    });

    var members = ["member--0", "member--1", "member--2"];
    
    for (var i = 0; i < members.length; i++) {
        var selector = '.member--' + i + ' .member__elipse';
        $(selector).hover(function () {
            $(this).toggleClass("member__elipse--hover");
            $(this).parent().toggleClass("hover");
        });
    }
    
    $('.team__button').click(function () {
       $('.team__list').toggleClass('team__list--visible');
    });
    $('.team__list-close').click(function () {
       $('.team__list').removeClass('team__list--visible');
    });

    $('#js-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        speed: 800,
        fade: true,
        arrows: false,
        cssEase: 'linear',
        asNavFor: '#js-slider-nav',
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    autoplay: false,
                }
            }
        ]
    });
    
    var slideCount = $('#js-slider .slick-slide').length;
    
    $('#js-slider-nav').slick({
        asNavFor: '#js-slider',
        slidesToShow: slideCount,
        slidesToScroll: 1,
        dots: false,
        focusOnSelect: true
    });
    
    $(".slider__content-button").click(function() {
        $('html, body').animate({
            scrollTop: $(".bottom-form").offset().top
        }, 2000);
    });
    
    $(".partnership-first__button").click(function() {
        $('html, body').animate({
            scrollTop: $(".form-partnership").offset().top
        }, 2000);
    });

    $('#js-carousel').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        // autoplaySpeed: 1500,
        arrows: false,
        // infinite: false,
        // speed: 800,
        // fade: true,
        // cssEase: 'linear'
        // asNavFor: '#js-slider-nav',
        // centerMode: true,
        // autoplay: true,
        // pauseOnHover: true,
        // pauseOnFocus: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    // autoplay: true,
                    // autoplaySpeed: 800,
                    centerMode: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            }
        ]
    });
    
    
    $('.carousel__item').hover(function () {
        var slide_id = $(this).attr('data-tab');
    
        $('.carousel__item').removeClass('carousel__item--active');
    
        $(this).addClass('carousel__item--active');
        $('.carousel__item--'+slide_id).addClass('carousel__item--active');
    
    });
    /*
    
    $('.carousel__item').each(function ( index ) {
        var textHeight = $(this).children('.carousel__text').innerHeight();
        console.log(textHeight);
        $(this).css('padding-top', textHeight);
        $(this).children('.carousel__text').css('height', textHeight);
    });*/

    $('.features__expand').click(function () {
        $(this).toggleClass('features__expand--open');
        $('.features__block').toggleClass('features__block--expanded');
    });

    var page = $('html').attr('id');
    
    $.ajaxSetup({
        type: 'POST',
        url: '../php/mail.php',
        beforeSend: function(data) {
            $('button[type="submit"]').prop('disabled', true);
        },
        success: function(){
            console.log('success');
            $('.form__message--success').fadeIn().delay(2000).fadeOut();
        },
        error: function(xhr, ajaxOptions, thrownError){
            console.log('error code = ' + xhr.status);
        },
        complete: function(){
            console.log('complete');
        //    TODO: add compleate message
        }
    });
    
    function subscribeSubmit(form) {
        var formData = {
            'page': page,
            'form_id': 'subscribe',
            'email': $('#subscribe_email').val()
        };
        $.ajax({
            data: formData,
            complete: function() {
                $(form)[0].reset();
                $('button[type="submit"]').prop('disabled', false);
            }
        });
    
        return false;
    }
    
    function contactSubmit(form) {
        var formData = {
            'page': page,
            'form_id': 'contact',
            'name': $('#contact_name').val(),
            'email': $('#contact_email').val(),
            'text': $('#contact_message').val()
        };
        $.ajax({
            data: formData,
            complete: function() {
                $(form)[0].reset();
                $('button[type="submit"]').prop('disabled', false);
            }
        });
    
        return false;
    }
    /*
    function trialSubmit(form) {
        var formData = {
            'form_id': 'trial',
            'name': $('#trial_name').val(),
            'email': $('#trial_email').val(),
            'company': $('#trial_company').val()
        };
        $.ajax({
            data: formData,
            complete: function() {
                $(form)[0].reset();
                $('button[type="submit"]').prop('disabled', false);
            }
        });
    
        return false;
    }
    
    function requestSubmit(form) {
        var formData = {
            'form_id': 'request',
            'name': $('#request_name').val(),
            'email': $('#request_email').val(),
            'company': $('#request_company').val()
        };
        $.ajax({
            data: formData,
            complete: function() {
                $(form)[0].reset();
                $('button[type="submit"]').prop('disabled', false);
            }
        });
    
        return false;
    }
    */

    $('#subscribe_form').validate({
        rules: {
            subscribe_email: {
                required: true,
                email: true
            }
        },
        messages: {
            subscribe_email: "Please enter a valid email address"
        },
        submitHandler: function(form) {
            subscribeSubmit(form);
        }
    });
    
    $('#contact_form').validate({
        rules: {
            contact_name: "required",
            contact_email: {
                required: true,
                email: true
            },
            contact_message: "required"
        },
        messages: {
            contact_name: "Please enter your full name",
            contact_email: "Please enter a valid email address",
            contact_message: "Please enter your message"
        },
        submitHandler: function(form) {
            contactSubmit(form);
        }
    });
    
    $('#trial_form').validate({
        rules: {
            trial_name: "required",
            trial_email: {
                required: true,
                email: true
            },
            trial_company: "required"
        },
        messages: {
            trial_name: "Please enter your full name",
            trial_email: "Please enter a valid email address",
            trial_company: "Please enter your company"
        },
        submitHandler: function(form) {
            trialSubmit(form);
        }
    });
    
    $('#request_form').validate({
        rules: {
            request_name: "required",
            request_email: {
                required: true,
                email: true
            },
            request_company: "required"
        },
        messages: {
            request_name: "Please enter your full name",
            request_email: "Please enter a valid email address",
            request_company: "Please enter your company"
        },
        submitHandler: function(form) {
            requestSubmit(form);
        }
    });

    // partnership cards
    
    var arr = $('.cards').children('.cards__item');
    
    arr.each(function (k, v) {
        $(this).waypoint(function (direction) {
            if (direction === 'down') {
                $(this.element).addClass('animate-top');
            } else if (direction === 'up') {
                $(this.element).removeClass('animate-top');
            }
        }, { offset: '100%' });
    });
    
    /*var waypoints = ['main-info'];
    
    waypoints.forEach(function (waypoint) {
        var waypointName = '.' + waypoint;
    
        $(this).waypoint(function (direction) {
            console.log(direction);
            console.log(waypointName);
            if (direction === 'down') {
                $(waypointName).addClass('animate');
            } else if (direction === 'up') {
                $(waypointName).removeClass('animate');
            }
        },
        {
            offset: '100%'
        })
    });*/

});